export const MAILCHIMP_SUBSCRIBE_URL =
  'https://infinum.us4.list-manage.com/subscribe/post?u=db2b64a5056c51cd3cc8423b7&amp;id=ca4489433a';

export const EMAIL_REGEX = /^.+@.+\..+$/i;
export const testEmailRegex = (value: string) => new RegExp(EMAIL_REGEX).test(value);

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
export const testPasswordRegex = (value: string) => new RegExp(PASSWORD_REGEX).test(value);

export const PREFER_SECONDARY_STREAM_KEY = 'preferSecondaryStream';

export const DEFAULT_TIMEZONE = 'UTC';

export const SESSION_ME = 'me';

export const LECTURE_CARD_IMAGE_FALLBACK = '/images/library/missing_poster.png';
