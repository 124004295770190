import styled from '@emotion/styled';

import { ButtonVariant, ButtonTheme } from '@/styles/themes/button';

import { CoreButton, ICoreButtonProps } from '../CoreButton';

/**
 * @deprecated use chakra button
 */
export const GhostButton = styled(CoreButton)<ICoreButtonProps>`
  padding: 0;
`;

GhostButton.defaultProps = {
  buttonTheme: ButtonTheme.LIGHT,
  variant: ButtonVariant.GHOST,
};
