import styled from '@emotion/styled';
import { FC } from 'react';

import { IThemeProps } from '@/styles/themes/types';

const ErrorMessageContainer = styled('p')<IThemeProps>`
  margin: 5px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.legacy.colors.status.error};
`;

interface IErrorMessageProps {
  errors: Record<string, any>;
  name: string;
}

export const ErrorMessage: FC<IErrorMessageProps> = ({ errors, name }) => {
  const error = errors[name];

  return error ? <ErrorMessageContainer>{error.message}</ErrorMessageContainer> : null;
};
