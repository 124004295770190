import styled from '@emotion/styled';
import { useState, forwardRef, SyntheticEvent, ChangeEvent } from 'react';
import { useToggle } from 'react-use';

import { FontFamily } from '@/enums/index';
import { ButtonTheme } from '@/styles/themes/button';
import { ChakraTheme } from '@/styles/themes/default';
import { IThemeProps } from '@/styles/themes/types';

import { GhostButton } from '../Button/variants/GhostButton';

const FieldWrapper = styled.div<IThemeProps>`
  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.IBMPlexSans]};
`;

const ShowPassword = styled(GhostButton)`
  position: absolute;
  right: 8px;
  bottom: 15px;
  text-transform: uppercase;
`;

interface IInputElementProps extends IThemeProps {
  variant: 'dark' | 'white';
}

const InputElement = styled.input<IInputElementProps>`
  width: 100%;
  padding: 12px 0;
  line-height: 24px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.legacy.colors.gray50};
  color: ${({ variant, theme }) => (variant === 'dark' ? theme.legacy.colors.black : theme.legacy.colors.white)};
  border-color: ${({ variant, theme }) =>
    variant === 'dark' ? theme.legacy.colors.gray50 : theme.legacy.colors.white};

  &:focus {
    outline: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: ${({ variant, theme }) =>
      variant === 'dark' ? theme.legacy.colors.black : theme.legacy.colors.white};
    -webkit-text-fill-color: ${({ variant, theme }) =>
      variant === 'dark' ? theme.legacy.colors.black : theme.legacy.colors.white};
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-caps-lock-indicator,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`;

const InputContainer = styled('div')`
  position: relative;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
  }
`;

const inputContainerStyle = (isActive: boolean, isError: boolean, variant: 'dark' | 'white') => (
  theme: ChakraTheme
) => {
  let backgroundColor = theme.legacy.colors.brand.secondary;

  if (isError) {
    backgroundColor = theme.legacy.colors.status.error;
  } else if (variant === 'dark') {
    backgroundColor = theme.legacy.colors.brand.primary;
  }

  return {
    '&:after': {
      display: isError || isActive ? 'initial' : 'none',
      backgroundColor,
    },
  };
};

const InputLabel = styled('label')<IThemeProps>`
  color: ${({ theme }) => theme.legacy.colors.lightText};
  display: block;
  transition: 0.25s;
  transform-origin: top left;
`;

function getLabelColor(isActive: boolean, hasError: boolean, theme: ChakraTheme, variant: 'dark' | 'white') {
  if (hasError) {
    return theme.legacy.colors.status.error;
  }

  if (isActive) {
    return variant === 'dark' ? theme.legacy.colors.brand.primary : theme.legacy.colors.brand.secondary;
  }

  return variant === 'dark' ? theme.legacy.colors.lightText : theme.legacy.colors.white;
}

const inputLabelStyle = (isActive: boolean, hasError: boolean, hasValue: boolean, variant: 'dark' | 'white') => (
  theme: ChakraTheme
) => {
  const translateYValue = isActive || hasValue ? '0' : '31px';
  const fontWeight = (isActive || hasValue ? 500 : 'normal') as number | 'normal';
  const fontSize = isActive || hasValue ? 14 : 16;

  return {
    transform: `translateY(${translateYValue})`,
    fontWeight,
    fontSize,
    lineHeight: '16px',
    color: getLabelColor(isActive, hasError, theme, variant),
  };
};

interface IFieldProps {
  label: string;
  name: string;
  type?: string;
  variant?: 'dark' | 'white';
  hasValue: boolean;
  isError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Field = forwardRef<undefined, IFieldProps>(
  ({ name, label, hasValue, isError = false, onChange, type = 'text', variant = 'dark' }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, toggleShowPassword] = useToggle(false);

    return (
      <FieldWrapper>
        <InputLabel htmlFor={name} css={inputLabelStyle(isFocused, isError, hasValue, variant)}>
          {label}
        </InputLabel>
        <InputContainer css={inputContainerStyle(isFocused, isError, variant)}>
          <InputElement
            type={type === 'password' && showPassword ? 'text' : type}
            ref={ref}
            id={name}
            name={name}
            variant={variant}
            css={type === 'password' && { paddingRight: 60 }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(event) => onChange && onChange(event)}
          />
          {type === 'password' && (
            <ShowPassword
              tabIndex={-1}
              type="button"
              buttonTheme={variant === 'dark' ? ButtonTheme.LIGHT : ButtonTheme.DARK}
              onClick={toggleShowPassword}
            >
              {showPassword ? 'hide' : 'show'}
            </ShowPassword>
          )}
        </InputContainer>
      </FieldWrapper>
    );
  }
);
